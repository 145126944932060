<template>
  <div class="print-list">
    <title-bar :title="$t('navigations.print')" />
    <div v-if="rows" class="custom-table">
        <advanced-filter
        :fields-array="filterFields"
        :action="showFilterModal"
        @clearAction="showFilterModal = false"
        @paramsChange="setAdvancedParams"
      />
      <table-actions
        :actions="['search', 'actionBtn', 'perPage','advancedFilter']"
        :actionBtnText="$t('messages.bulkPrint')"
        actionBtnIcon="printer"
        :option="filterOption"
        :options="filterOptions"
        :entries="pages"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        @filterChange="showFilterModal = true"
        @actionBtnClicked="bulkPrintSubmitted"
        :defaultPerPage="perPage"
      />
      <table-lite
        ref="tableLite"
        :is-slot-mode="true"
        :has-checkbox="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
        @return-checked-rows="updateCheckedRows"
      >
        <template v-slot:item="data">
          <div v-if="data.value.item">
            <div v-if="data.value.item.number">{{ data.value.item.number }}</div>
            <div v-else>Item ID {{ data.value.item.id }}</div>
          </div>
          <div v-else>No item</div>
        </template>
        <template v-slot:templateName="data">
          <div v-if="data.value.templateName">
            {{ $helper.getEnumTranslation("PDF_TEMPLATE_NAME", data.value.templateName, this.$i18n.locale) }}
          </div>
        </template>
        <template v-slot:createdAt="data">
          <div v-if="data.value.createdAt">
            {{ $helper.formatDate(data.value.createdAt) }} {{ $helper.formatTime(data.value.createdAt) }}
          </div>
        </template>
        <template v-slot:printed="data">
          <div v-if="data.value.printed">{{ $t("forms.yes") }}</div>
          <div v-else>{{ $t("forms.no") }}</div>
        </template>
        <template v-slot:recentPrintedAt="data">
          <div v-if="data.value.recentPrintedAt">
            {{ $helper.formatDate(data.value.recentPrintedAt) }} {{ $helper.formatTime(data.value.recentPrintedAt) }}
          </div>
        </template>
        <template v-slot:createdBy="data">
          <div v-if="data.value.user">
            {{ data.value.user.givenName }} {{ data.value.user.familyName }}
          </div>
        </template>
        <template v-slot:recentPrintedBy="data">
          <div v-if="data.value.recentPrintedBy">
            {{ data.value.recentPrintedBy.givenName }} {{ data.value.recentPrintedBy.familyName }}
          </div>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <print-pdf-btn :pdf-resource="data.value['@id']" :pdf-url="data.value.notCachedUrl" @pdfPrinted="pdfPrinted" />
            <b-button v-if="$helper.isRoleVibrationAdmin()" variant="danger" @click="regeneratePdf(data.value)">
              <BootstrapIcon icon="arrow-clockwise" size="1x"/>
            </b-button>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>

  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import PrintPdfBtn from "@/components/Buttons/PrintPdfBtn.vue";
import {useToast} from "vue-toastification";
import Toast from "@/components/Toast.vue";

export default {
  name: "Print",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    TitleBar,
    AdvancedFilter,
    PrintPdfBtn
  },
  mixins: [ResourceUtils],
  created() {
    this.load()
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      search: "",
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      showFilterModal: false,
      checkedRows: [],
      sortable: {
        order: "createdAt",
        sort: "desc",
      },
      filterFields: [
        {
          label: "forms.createdBy",
          name: "user",
          value: null,
          type: "customSelect",
          resource: this.$Users,
          resourceType: "employee",
        },
        {
          label: 'forms.labelType',
          name: "templateName",
          value: null,
          type: "select",
          list: [
            {
              id: 'MAINTENANCE_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "MAINTENANCE_LABEL", this.$i18n.locale)
            },
            {
              id: 'MAINTENANCE_LOG_BOOK_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "MAINTENANCE_LOG_BOOK_LABEL", this.$i18n.locale)
            },
            {
              id: 'CALIBRATION_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "CALIBRATION_LABEL", this.$i18n.locale)
            },
            {
              id: 'HANDTOOL_CALIBRATION_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "HANDTOOL_CALIBRATION_LABEL", this.$i18n.locale)
            },
            {
              id: 'HANDTOOL_MARKING_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "HANDTOOL_MARKING_LABEL", this.$i18n.locale)
            },
            {
              id: 'HANDTOOL_LOCATION_BAG_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "HANDTOOL_LOCATION_BAG_LABEL", this.$i18n.locale)
            },
            {
              id: 'APPLICATOR_MARKING_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "APPLICATOR_MARKING_LABEL", this.$i18n.locale)
            },
            {
              id: 'APPLICATOR_LOCATION_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "APPLICATOR_LOCATION_LABEL", this.$i18n.locale)
            },
            {
              id: 'EXTRACTION_TOOL_BAG_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "EXTRACTION_TOOL_BAG_LABEL", this.$i18n.locale)
            },
            {
              id: 'EXTRACTION_TOOL_MARKING_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "EXTRACTION_TOOL_MARKING_LABEL", this.$i18n.locale)
            },
            {
              id: 'EXTRACTION_TOOL_LOCATION_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "EXTRACTION_TOOL_LOCATION_LABEL", this.$i18n.locale)
            },
            {
              id: 'LAYBOARD_LABEL',
              label: this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", "LAYBOARD_LABEL", this.$i18n.locale)
            },
          ]
        },
        {
          label: 'forms.isPrinted',
          name: "printed",
          value: null,
          type: "select",
          list: [
            {
              id: true,
              label: this.$t('forms.yes')
            },
            {
              id: false,
              label: this.$t('forms.no')
            }
          ]
        }
      ],
      advancedParams: [],
      columns: [
        {
          label: this.$t("forms.id"),
          field: "id",
          sortable: true,
          isKey: true,
        },
        {
          label: this.$t("forms.item"),
          field: "item",
          sortable: false,
        },
        {
          label: this.$t("forms.label"),
          field: "templateName",
          sortable: true,
        },
        {
          label: this.$t("forms.createdBy"),
          field: "createdBy",
          sortable: true,
        },
        {
          label: this.$t("forms.createdAt"),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t("forms.isPrinted"),
          field: "printed",
          sortable: false,
        },
        {
          label: this.$t("forms.lastPrintedAt"),
          field: "recentPrintedAt",
          sortable: false,
        },
        {
          label: this.$t("forms.lastPrintedBy"),
          field: "recentPrintedBy",
          sortable: false,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
        },
      ],
      rows: null,
      allowedTemplateName: null,
    };
  },
 methods: {
    inputChange(value) {
        this.currentPage = 1;
        this.search = value;
        this.load();
    },
   updateCheckedRows(data) {
     this.checkedRows = [];
     this.allowedTemplateName = null;
     const disabledRowIndexes = []

     data.forEach((value) => {
       const row = {
         pdfId: value,
         pdf: this.rows.find(row => parseInt(row.id) === parseInt(value)),
         index: this.rows.findIndex(row => parseInt(row.id) === parseInt(value)),
       }

       if (null === this.allowedTemplateName) {
         this.allowedTemplateName = row.pdf.templateName;

         this.rows.forEach((r, i) => {
           if (this.allowedTemplateName !== r.templateName) {
             // this.$refs.tableLite.$refs.localTable.rows start with thead row
             disabledRowIndexes.push(i + 1)
           }
         })
       }

       this.checkedRows.push(row)
     })

     // remove disabled class from all
     for (let i = 0; i < this.$refs.tableLite.$refs.localTable.rows.length; i++) {
       if (typeof this.$refs.tableLite.$refs.localTable.rows[i] !== 'undefined') {
         this.$refs.tableLite.$refs.localTable.rows[i].classList.remove('disabled')
       }
     }

     // add disabled class
     if (this.allowedTemplateName && disabledRowIndexes.length > 0) {
       // disable check-all checkbox
       this.$refs.tableLite.$refs.localTable.rows[0].classList.add('disabled')
       disabledRowIndexes.forEach((i) => {
         if (typeof this.$refs.tableLite.$refs.localTable.rows[i] !== 'undefined') {
           this.$refs.tableLite.$refs.localTable.rows[i].classList.add('disabled')
         }
       })
     }
   },
    setAdvancedParams(data) {
        this.advancedParams = data
        this.load()
    },
    load() {
        const params = {
            itemsPerPage: this.perPage,
            page: this.currentPage,
            templateName: [
              'MAINTENANCE_LABEL',
              'MAINTENANCE_LOG_BOOK_LABEL',
              'CALIBRATION_LABEL',
              'HANDTOOL_CALIBRATION_LABEL',
              'HANDTOOL_MARKING_LABEL',
              'HANDTOOL_LOCATION_BAG_LABEL',
              'APPLICATOR_MARKING_LABEL',
              'APPLICATOR_LOCATION_LABEL',
              'EXTRACTION_TOOL_BAG_LABEL',
              'EXTRACTION_TOOL_MARKING_LABEL',
              'EXTRACTION_TOOL_LOCATION_LABEL',
              'LAYBOARD_LABEL',
            ],
        };

        params["order[" + this.sortable.order + "]"] = this.sortable.sort;

        if (this.userData.role === "ROLE_EMPLOYEE") {
          params.user = this.userData['@id']
        }

        if (this.search && this.search.length) {
            params.search = this.search;
        }
        this.advancedParams.forEach(element => {
            if (element.value !== '' && element.value !== null) {
                params[element.name] = element.value
            }
        });
        this.$Pdfs.getCollection({params}, 'pdf_list').then((response) => {
            this.pages = response.data["hydra:totalItems"];
            this.rows = response.data["hydra:member"];
        });
    },
    printPdf(pdf) {
      const link = document.createElement("a");
      link.href = pdf.notCachedUrl;
      link.target = "_blank";
      link.click();
    },
    pdfPrinted(params) {
      this.rows.forEach((pdf) => {
        if (pdf['@id'] === params.pdfResource) {
          pdf.printed = true
          pdf.printedAt = params.printHistory.createdAt
          pdf.printedBy = params.printHistory.user
        }
      })
    },
   bulkPrintSubmitted() {
      if (this.checkedRows.length === 0) {
        this.$helper.showToast(
            useToast(),
            Toast,
            this.$t('messages.noPdfSelected'),
            this.$t('messages.selectAtLeastOnePdf'),
            "danger"
        );
      }

     if (this.checkedRows.length > 0) {

       const body = {
         resources: this.checkedRows.map((row) => row.pdf['@id']),
         name: 'bulk_label',
         templateName: 'BULK_LABEL',
       }

       this.$Pdfs
           .createResourceByUrl({ url: `/pdfs/bulk_print`, body: body })
           .then((response) => {
             if (response.status === 200) {
               this.load()
               this.printPdf(response.data)
             }
           });
     }
   },
   regeneratePdf(pdf) {
     const body = pdf
     body.item = body.item['@id']
     body.user = body.user['@id']
     if (body.printedBy) {
       body.printedBy = body.printedBy['@id']
     }
     delete(body.recentPrintedBy)
     this.$Pdfs
       .updateResourceByUrl({ url: `/pdfs/${pdf.name}/regenerate`, body: body })
       .then((response) => {
         if (response.status === 200) {
           this.load()
           this.printPdf(response.data)
         }
       });
   },
   setSort(offset, limit, order, sort) {
     this.sortable.sort = sort;
     this.sortable.order = order;

     if (order === 'createdBy') {
       this.sortable.order = 'user.givenName';
     }

     this.load();
   },
   onChangePage(page) {
     this.currentPage = page;
     this.load();
   },
 },
};
</script>

